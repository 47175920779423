import React, {Component} from 'react';
import SubpageHeader from '../header/SubpageHeader';
import Footer from '../Footer';

import { ReactComponent as IconInfo } from '../../icons/info.svg';
import { ReactComponent as IconTrendUpSquare } from '../../icons/trend-up-square.svg';
import { ReactComponent as IconTarget } from '../../icons/target.svg';
import { ReactComponent as IconPhone } from '../../icons/phone.svg';

class DesignCritMVP extends Component{
  constructor(props){
    super(props);
    this.viewName = "DesignCritMVP";
  }
  componentDidUpdate(){
    // console.log("did update", this);
  }
  componentDidMount(){
    // console.log("did mount", this);
    window.scrollTo({ top: 0 });
  }
  
  render(){
    return (
      <div >
        <SubpageHeader eyebrowText={"Case Study"} pageHeaderText={"Zero-to-One AI Product for Designers"}  />

        <section className="hero-content subpage-hero">
          <img src="/images/designcrit/1__9fIOHOSCCnKzLMq-gOXAA.webp" alt="Zero-to-One AI Product for Designers"  />
        </section>

        <section className="content">
          <p><i>Last Updated: October 12, 2024.</i></p>

          
          <h2>Quick Links</h2>
          <p>
          Product Landing:&nbsp;<a target="_blank" href="https://designcrit.ai/"> https://designcrit.ai </a><br/>
          Figma Plugin:&nbsp;<a target="_blank" href="https://www.figma.com/community/plugin/1415053708566903149/designcrit-ai"> Figma.com/community/plugin/designcrit-ai </a><br/>
          </p>



          <h2>Background</h2>
          <p>I’ve been interested in the intersection of design & machine learning for quite a few years now. So, what better way to learn more about it then to throw myself into creating a new product that tries to solve problems for my fellow designers.</p>
          <p><b>I built something similar before</b><br/>
          A few years ago, when I was still running Adjust Creative, I built a small AI tool prototype for homepage design critiques using IBM Watson. At the time, NLMs were in a pretty new state, so I couldn’t just use them out of the box, I had to train the model myself with a lot of screenshot images of websites and then give each image a weighting scale for categories I wanted the model to grade the design. These categories were design fundamentals like color theory, fonts, and layout coupled with more product squeeze page metrics like call-to-action, clarity of communication, value statement, etc.</p>
          <p><b>AI has come a long way since</b><br/>
          It was a lot of training for an arguably mediocre tool. But, it taught me about the power of AI, how to continuously train models, and we used it as a lead generator to capture some folks looking for better design on their websites.</p>
          <h2>Why build this?</h2>
          <p>This specific question happened in a 1:1 with a designer recently:</p>
          <p className="blockquote">What if there were a tool where I could get this quick feedback without bothering you, that way I could just talk to you about larger problems?</p>
          <p>I didn’t feel bothered. I’m happy to give other folks feedback and help when I can. But, she posed an interesting question. This person was very much looking for quick and direct feedback that showed me an opportunity to learn, help, and make cool things.</p>

          <img className="inline-img" src="/images/designcrit/designerworking.png" alt="Designer working" />
          {/*image: designer */}

          <p><b>Remote designers need quick feedback</b><br/>
          I work as a designer, I’ve been hybrid or remote for years. I miss the office sometimes—usually because I could lean over to a colleague and get quick feedback about whatever I’m working on.</p>
          <p><b>Less experienced designers need guidance</b><br/>
          A lot of designers who haven’t yet built up skills to make certain decisions quickly based on experience and intuition need the guidance of someone senior at their reach.</p>
          <h2>Where should I build this?</h2>
          <p><b>Figma plugin first</b><br/>
          Because my idea was for designers specifically, there’s no better place to build and test the prototype than in a Figma plugin. I have experience building Figma plugins—that’s where designers are—so that’s where the bulk of the prototype development went.</p>

          <img className="inline-img" src="/images/designcrit/1_DWU6F_iEm4mY-eIHMo4YOA.webp" alt="Figma is awesome" />
          {/*image: Figma is awesome*/}

          <p><b>Web UI or other</b><br/>
          There could be multiple tiers to this project in the future, including a full featured web dashboard. But, for prototypes and getting something out the door, I only need a Figma plugin and a product landing page.</p>
          <p>A couple of key points:<br/>
          <ul>
          <li>Figma plugin works directly with Figma files</li>
          <li>Web takes a URL and gives you some general feedback</li>
          <li>Web UI points you to Figma plugin</li>
          <li>Web UI drives you to contact me</li>
          </ul>
          </p>
          <h2>What were the biggest challenges?</h2>
          <p><b>Being opinionated about the interaction design</b><br/>
          Allowing free prompt vs making it UI based prompting. I think for most users, especially ones that aren’t familiar with AI tools yet, the button/settings based version is more useful. Maybe there could be a more power user mode for advanced users.</p>
          <h3>Google Cloud AI</h3>
          <p><b>Why Google Cloud?</b><br/>
          I tested OpenAI and Google Cloud with this app. Google Gemini seemed to respond slightly better than OpenAI and more consistently. However, I’m still evaluating this decision. These things are improving an a crazy speed, so they sort of leap frog each other. There’s also a possibility of getting a machine, downloading Meta’s ML models and hosting it. But, that’s a much bigger level of effort.</p>
          <p><b>Google Cloud AI Budget limits</b><br/>
          There’s no budget limit on Google Cloud AI—which means you could accidentally go bankrupt if it gets wildly popular in a short amount of time. For the beta, I had to write in a request-per-day limit on the ruby app to make sure things don’t get out of hand for the testing phase…</p>
          <p><b>Markdown conversion</b><br/>
          The API responds with markdown and needs to be converted.</p>

        {/*  <pre>{`
    // first get the message
    response = body.api_response;
    if(DEVMODE) console.log('test-image[8], embed the prompt and image: ', body);
    // then convert it from markdown to html
    let converter = new showdown.Converter();
    //
    let text_html = converter.makeHtml(response);
    responseTextDiv.innerHTML = text_html;
    //
    let img_html = '<img class="response-embedded-image" src="data:image/png;base64,' + body.state_data.image + '" />';
    responseImgDiv.innerHTML = img_html;
    // change the state of the app to "viewing response"
    setAppViewState(APP_VIEWSTATE_RESULTS);
          `}</pre>
          */}

          {/*image: early prototype dev */}
          <img className="inline-img" src="/images/designcrit/1_zqGISVhhsJRK5nMFY7T5dQ.webp" alt="early prototype" />

          <h3>Figma Dev</h3>
          <p><b>Uint8Array to Base64</b><br/>
          Converting large arrays needed to be broke into chunks for performance reasons</p>
          <p><b>Figma Plugin UI vs Backend</b><br/>
          Was a little tricky realizing a lot of data needs to be processed on Node backend vs UI window and how that data is sent back and forth. So, it meant creating a very event based app.</p>
          <p>A lot of changes that require screen updates need to post messages to the Node backend to make the decisions, then that backend needs to send a message back to the Plugin UI to update.</p>

         {/* <pre>{`
    // when clicking buttons in the UI, send messages to Node backend
    const classList = element.className.split(' ');
    if( hasClass(classList,'prompt-personas') ){
      appStateData.persona = id;
      _postMessage('change-prompt-settings', {
        key: 'persona',
        value: id
      });
    }else if( hasClass(classList,'prompt-design-context') ){
      appStateData.designContext = id;
      _postMessage('change-prompt-settings', {
        key: 'context',
        value: id
      });
    }else if( hasClass(classList,'prompt-selection-type') ){
      appStateData.selectionContext = id;
      _postMessage('change-prompt-settings', {
        key: 'selection-type',
        value: id
      });
    }else if( hasClass(classList,'prompt-recommendation') ){
      appStateData.recommendation = id;
      _postMessage('change-prompt-settings', {
        key: 'recommendation',
        value: id
      });
    }
    // wrapping function for sending messages
    function _postMessage(type, data){
      // any checks here...
      // send to backend
      parent.postMessage({ 
        pluginMessage: {
          messageType: type,
          data: data
        }
      }, '*');
    }
          `}</pre>*/}

          <p><b>Building the right prompts</b><br/>
          Since the prototype of this is going to be utilizing generative AI, we need to be able to prompt it correctly. Giving a very specific prompt in the right way is a very detailed task.</p>
          <p>The way I set up the plugin is that the prompt has multiple levels of instructions that then get compiled and sent to the server.</p>

          {/*image: quick example of the multiple levels of prompt configuration*/}
          <img className="inline-img" src="/images/designcrit/1_JjJdwyKwGkh36nwLCURjJQ.webp" alt="prompts" />

          <p>This part will change the most until the testers and I get it to be as valuable as it can with this level of generative AI. Then, from that point, I’ll start training the models with expert data so it has better context and understanding per each topic.</p>
          <h3>Ruby on Rails</h3>
          <p><b>Google Gemini tokens</b><br/>
          The API responds with tokens while it’s processing and streaming data to you, so you need to concat it afterwards or stream chunks to the UI.</p>
{/*
          <pre>{`
    # connect to the gemini api
    client = Gemini.new(
      credentials: {
       service: 'vertex-ai-api',
       file_contents: 'credsgohere',
       region: 'us-east4'
     },
      options: { model: 'gemini-pro-vision', server_sent_events: true }
    )
    # encode the image as Base64 and add its MIME type:
    candidates = client.stream_generate_content(
      { contents: [
        { role: 'user', parts: [
          { text: prompt },
          { inline_data: {
            mime_type: 'image/jpeg',
            data: image
          } }
        ] }
      ] }
    )
    # convert the response tokens to a single text response
    response = ''
    for item in candidates
     text = item.dig('candidates',0,'content','parts',0,'text')
     if not text.nil?
      response = response << text
     end
    end
          `}</pre>
*/}
          <h3>Design Goals</h3>
          <p><b>Easy to use user interface with point-and-click prompt.</b><br/>
          Needs to have modern and cute brand voice to offset the “AI of Doom” vibe that a lot of folks get from fresh technologies.</p>

        </section>
        <section className="hero-content">
          {/* image: Left: Early prototype showing v1 robot, prompt field and response text. Right: updated design elements to feel more polished.*/}
          <img className="inline-img" src="/images/designcrit/progress-screenshots.png" alt="progress screenshots" />
        </section>
        <section className="content">

          <h2>MVP Launch</h2>
          <p><b>Let’s go!</b><br/>
          As soon as I had all the necessary tech and design in place and it functioned as intended—I rolled out the product publicly. Like, literally within 2 hours.</p>
          <p>I got the domain <a target="_blank" href="https://designcrit.ai">DesignCrit.ai</a> after running through about 10–20 name ideas + generating many more with ChatGPT.</p>
          <p>Built a pretty quick product landing page so that there’s a bit of face for this application and a place to store documentation, FAQs, and testimonials when they are ready.</p>

          {/*Screenshot of DesignCrit.ai product page*/}
          <img className="inline-img" src="/images/designcrit/1_5X5NGm7O59uc3ipMzemk3w.webp" alt="product page screenshot" />

          <h2>User Testing</h2>
          <p>With the plugin in the Figma plugin community, I asked UX designers on LinkedIn to help me test it. The best way to test features is with an active community, so I started a Discord server for us to throw ideas around in to improve the plugin.</p>
          <p>There are categories like Design Feedback, Prompt Response Feedback, and Bugs in the beta testing group. In the first two weeks, there are already a handful of new product requests and updates!</p>
          <p>If you’re a designer or programmer and would like to get involved in helping iron out the beta of this plugin, feel free to <a target="_blank" href="https://www.linkedin.com/in/atebit/">reach out to me to request access</a> to the Discord.</p>

          {/*https://www.linkedin.com/in/atebit/*/}
          <a target="_blank" href="https://www.linkedin.com/in/atebit/">
            <img className="inline-img" src="/images/designcrit/1_nzsi4ahaCLFiGYMPeUWaZA.webp" alt="Linkedin" />
          </a>

          <h2>Future Iterations</h2>
          <p>As of October 2024, I’m currently honing the product and I’m adding to it as I have time after work and on weekends. I would love for this to become useful enough that senior designers could benefit from it just as much as junior designers or even devs trying to get some quality feedback.</p>
          <p>My next major dev effort is going to be creating the user authentication and API key requirements so I can better track who’s using the plugin and AI resources.</p>
          <p><i>* Note: When I have more, I’ll edit and update this article.</i></p>

          <p><i>I also <a href="https://medium.com/@movepixels/case-study-zero-to-one-ai-product-for-designers-c94a9ad902ff" target="_blank">put this article on Medium</a>, but with some code blocks in case it helps someone else out there trying to work on similar things.</i></p>


        </section>
        <Footer />
      </div>
    )
  }
}

export default DesignCritMVP;
